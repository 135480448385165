@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.silver-glow {
    color: white;  /* Sets the primary text color to white */
    text-shadow:
            0 0 5px rgba(255, 255, 255, 0.5),  /* Medium white glow */
            0 0 10px rgba(255, 255, 255, 0.3),  /* Larger, more spread out white glow */
            0 0 15px rgba(255, 255, 255, 0.2);  /* Even larger and softer glow */
}
.section {
    scroll-margin-top: 8vh; /* Height of the navbar plus some extra space */
}
